<template>
    <div class="pages" v-loading="loading">
        <table-height-auto>
            <template slot="before">
                <el-form :inline="true" class="demo-form-inline">
                    <div class="search">
                        <div>
                            <el-form-item label="租赁土地标题">
                                <el-input
                                    v-model="search.name"
                                    clearable
                                    placeholder="模糊搜索"
                                    style="width: 160px;"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="土地编码">
                                <el-input
                                    v-model="search.code"
                                    clearable
                                    placeholder="精确搜索"
                                    style="width: 160px;"
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="土地分区">
                                <el-select
                                    v-model="search.landDistrictId"
                                    placeholder=""
                                    style="width: 160px;"
                                >
                                    <el-option
                                        v-for="item in landdistrictList"
                                        :key="item.id"
                                        :label="item.districtNo"
                                        :value="item.id"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="状态">
                                <el-select
                                    v-model="search.status"
                                    placeholder=""
                                    style="width: 160px;"
                                >
                                    <el-option
                                        v-for="item in statusList"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item>
                                <el-button
                                    type="primary"
                                    size="medium"
                                    @click="getList"
                                    >查询</el-button
                                >
                                <el-button
                                    type="primary"
                                    size="medium"
                                    @click="reset"
                                    >重置</el-button
                                >
                            </el-form-item>
                        </div>
                    </div>
                </el-form>
            </template>

            <template v-slot:table="row">
                <div>
                    <el-button
                        type="primary"
                        size="medium"
                        @click="edit({})"
                        >新增租赁土地</el-button
                    >
                    <el-button
                        type="primary"
                        size="medium"
                        @click="subDialogVisible = true"
                        >土地分区配置</el-button
                    >
                </div>
                <el-table
                    v-if="row.height"
                    :height="row.heightText"
                    :data="tableData"
                    style="width: 100%"
                >
                    <el-table-column type="index" width="50" label="序号" />
                    <el-table-column
                        prop="date"
                        label="图片/视频"
                        width="140"
                        align="center"
                    >
                        <template #default="scope">
                            <el-image
                                class="avatar"
                                @click="showBox(scope.row.pictures.pics)"
                                fit="cover"
                                :src="
                                    scope.row.pictures.pics.length
                                        ? scope.row.pictures.pics[0]
                                        : ''
                                "
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="租赁土地标题"
                        width="140"
                    />
                    <el-table-column
                        prop="code"
                        label="土地编码"
                        show-overflow-tooltip
                        width="120"
                    />
                    <el-table-column prop="num" label="农场管理员" width="160">
                        <template #default="scope">
                            <div v-if="scope.row.farmAdminUser" style="display: flex;flex-direction: column;justify-content: center;">
                                <div style="text-align: center;">
                                    <el-image
                                        :src="scope.row.farmAdminUser.farmAdminIcon || 'http://imgs.cunzhiliao.com/village-cloud-files/img/mine/defaultAvatar.png'"
                                        style="with: 30px;height: 30px;border-radius: 50%;object-fit: cover;"
                                        :preview-src-list="[
                                            scope.row.farmAdminUser
                                                .farmAdminIcon
                                        ]"
                                    />
                                </div>
                                <div>
                                    <span
                                        >{{
                                            scope.row.farmAdminUser
                                                .farmAdminName
                                        }}&ensp;</span
                                    ><span>{{
                                        scope.row.farmAdminUser.farmAdminPhone
                                    }}</span>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态" width="90">
                        <template #default="scope">{{
                            scope.row.status === 0
                                ? "下架"
                                : scope.row.status === 1
                                ? "上架"
                                : ""
                        }}</template>
                    </el-table-column>
                    <el-table-column
                        prop="feeOfFirstRent"
                        label="租赁物料费用"
                        width="140"
                    ><template #default="{row}">￥{{
                            row.feeOfFirstRent
                        }}</template>
                    </el-table-column>
                    <el-table-column
                        prop="packages"
                        label="关联租赁套餐"
                        width="140"
                    >
                        <template #default="scope"
                            ><span
                                v-for="(item, i) in scope.row.packages"
                                :key="i"
                                >{{ item.name
                                }}{{
                                    scope.row.packages &&
                                    i == scope.row.packages.length - 1
                                        ? ""
                                        : ","
                                }}</span
                            ></template
                        >
                    </el-table-column>
                    <el-table-column
                        prop="landDistrictName"
                        label="土地分区"
                        width="120"
                    >
                        <template #default="scope"
                            >{{ scope.row.landDistrictName
                            }}{{
                                scope.row.landDistrictName ? "" : ""
                            }}</template
                        >
                    </el-table-column>
                    <el-table-column
                        prop="landArea"
                        label="土地总面积"
                        width="120"
                    >
                        <template #default="scope"
                            >{{ scope.row.landArea
                            }}{{ scope.row.landArea ? "m²" : "" }}</template
                        >
                    </el-table-column>
                    <el-table-column label="单位租赁面积" width="120">
                        <template #default="scope"
                            >{{ scope.row.rentConfig.rentMinArea
                            }}{{
                                scope.row.rentConfig.rentMinArea ? "m²" : ""
                            }}</template
                        >
                    </el-table-column>
                    <el-table-column label="单位种植面积" width="120">
                        <template #default="scope"
                            >{{ scope.row.rentConfig.plantMinArea
                            }}{{
                                scope.row.rentConfig.plantMinArea ? "m²" : ""
                            }}</template
                        >
                    </el-table-column>
                    <el-table-column width="120" label="分区号段">
                        <template #default="scope">
                            <div style="white-space: nowrap;">
                                {{ `${scope.row.blockNoStartCode}-`
                                }}{{ scope.row.blockNoEndCode }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="添加时间"
                        width="160"
                        align="center"
                    />
                    <el-table-column
                        prop="date"
                        label="操作"
                        width="220"
                        fixed="right"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="primary"
                                @click="unmount(scope.row, 1)"
                                v-if="scope.row.status == 0"
                                >上架</el-button
                            >
                            <el-button
                                size="mini"
                                type="primary"
                                @click="unmount(scope.row, 0)"
                                v-if="scope.row.status == 1"
                                >下架</el-button
                            >
                            <el-button
                                size="mini"
                                type="primary"
                                @click="edit(scope.row)"
                                >编辑</el-button
                            >
                            <el-button
                                size="mini"
                                type="danger"
                                @click="del(scope.row, '编辑')"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </template>

            <template slot="after">
                <div class="paging-row">
                    <el-pagination
                        v-if="total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[5, 10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </template>
        </table-height-auto>
        <el-dialog
            title="查看"
            append-to-body
            style="z-index: 5001;"
            class="my-dialog"
            :visible.sync="isVisible"
            width="800px"
        >
            <el-carousel
                v-if="isVisible"
                height="430px"
                :autoplay="false"
                trigger="click"
                :initial-index="initialIndex"
            >
                <!-- <el-carousel-item v-for="item in list" :key="item"> -->
                <el-image
                    v-if="showData.type == 1"
                    :src="showData.img"
                    fit="contain"
                    style="width: 760px;height: 430px;"
                />
                <video
                    v-else-if="showData.type == 2"
                    :src="showData.video"
                    controls="controls"
                    style="width: 760px;height: 430px;"
                >
                    您的浏览器不支持视频播放
                </video>
                <el-empty
                    v-else
                    :image-size="180"
                    description="数据格式有误！"
                />
                <!-- </el-carousel-item> -->
            </el-carousel>
            <template #footer>
                <el-button type="primary" @click="isVisible = false"
                    >确定</el-button
                >
            </template>
        </el-dialog>
        <addRendLand
            @closeAdd="closeAdd"
            :addDialogVisible="addDialogVisible"
            :currentRow="currentRow"
        />
        <subAreaLand
            @closeSub="closeSub"
            :subDialogVisible="subDialogVisible"
            :currentRow="currentRowSub"
        />
    </div>
</template>

<script>
import addRendLand from "./components/addRendLand.vue";
import subAreaLand from "./components/subAreaLand.vue";
import { URL } from "../../../config";
import fa from "element-ui/src/locale/lang/fa";

export default {
    name: "landConfig",
    components: {
        addRendLand,
        subAreaLand
    },
    data() {
        return {
            search: {
                code: "",
                landDistrictId: "",
                name: "",
                status: "",
                supplierId: ""
            },
            loading: false,
            isVisible: false,
            tableData: [],
            currentPage: 1,
            total: 0,
            pageSize: 10,
            addDialogVisible: false,
            subDialogVisible: false,
            currentRow: {},
            currentRowSub: {},
            statusList: [
                { label: "全部", value: "" },
                { label: "上架", value: 1 },
                { label: "下架", value: 0 }
            ],
            landdistrictList: []
        };
    },
    methods: {
        getList() {
            this.axios
                .post(URL.farm.farmLandPage, {
                    ...this.search,
                    pageNo: this.currentPage,
                    pageSize: this.pageSize
                })
                .then(res => {
                    if (res.code === "0") {
                        this.tableData = res.data.records;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },

        edit(row) {
            if (row) {
                this.addDialogVisible = true;
                this.currentRow = row;
            }
        },

        unmount(row, status) {
            this.$confirm(
                `是否切换状态为${status === 0 ? "下架" : "上架"}`,
                `${status === 0 ? "下架" : "上架"}提示`,
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            )
                .then(() => {
                    this.axios
                        .put(URL.farm.farmLandUpdateStatus, {
                            id: row.id,
                            status
                        })
                        .then(res => {
                            if (res.code === "0") {
                                this.$message({
                                    type: "success",
                                    message: "修改成功!"
                                });
                                this.getList();
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
                });
        },

        del(row) {
            this.$confirm(
                "删除后对应前端共享农场不可查看该土地，是否确认删除？",
                "删除提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            )
                .then(() => {
                    this.axios
                        .post(URL.farm.farmLandDelete, { id: row.id })
                        .then(res => {
                            if (res.code === "0") {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!"
                                });
                                this.getList();
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消"
                    });
                });
        },

        closeAdd(e) {
            this.addDialogVisible = false;
            if (e === "get") this.getList();
        },

        closeSub() {
            this.subDialogVisible = false;
        },

        reset() {
            this.search = {
                code: "",
                landDistrictId: "",
                name: "",
                status: "",
                supplierId: ""
            }
            this.currentPage = 1;
            this.pageSize = 10;
            this.getList();
        },

        handleSizeChange(val, type) {
            this.pageSize = val;
            this.currentPage = 1;
            this.getList();
        },

        handleCurrentChange(val, type) {
            this.currentPage = val;
            this.getList();
        },

        getlanddistrictList() {
            this.axios.get(URL.farm.farmLanddistrictList).then(res => {
                if (res.code === "0") {
                    this.landdistrictList = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        }
    },
    mounted() {
        this.getList();
        this.getlanddistrictList();
    }
};
</script>

<style lang="less">
.pages {
    .search {
        display: flex;
        justify-content: space-between;
    }
}

.avatar {
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
</style>
