<template>
    <div>
        <el-dialog
            title="土地分区配置"
            :visible.sync="visible"
            width="850px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :before-close="close"
        >
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column type="index" width="50" label="序号" />
                <el-table-column
                    prop="districtNo"
                    label="土地区号"
                    width="180"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-input
                            v-if="scope.row.isInput"
                            v-model="scope.row.districtNo"
                            placeholder="请输入区号"
                        />
                        <span v-else>{{ scope.row.districtNo }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="rentMinArea" width="180" align="center">
                    <template slot="header">
                        单位租赁面积
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="单位租赁面积是指可以单次租赁给用户的土地面积是多大"
                            placement="top"
                        >
                            <i class="el-icon-question" />
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                        <el-input
                            @input="validateInput(scope.row)"
                            type="number"
                            v-if="scope.row.edit"
                            v-model="scope.row.rentMinArea"
                            placeholder=""
                        />
                        <span v-else>{{ scope.row.rentMinArea }}m²</span>
                    </template>
                </el-table-column>
                <el-table-column prop="plantMinArea" width="180" align="center">
                    <template slot="header">
                        单位种植面积
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="单位种植面积是指用户租赁成功后，在租赁成功的土地上可以按照多少平米来进行单次播种"
                            placement="top"
                        >
                            <i class="el-icon-question" />
                        </el-tooltip>
                    </template>
                    <template slot-scope="scope">
                        <el-input
                            @input="validateInputnext(scope.row)"
                            type="number"
                            v-if="scope.row.edit"
                            v-model="scope.row.plantMinArea"
                            placeholder=""
                        />
                        <span v-else>{{ scope.row.plantMinArea }}m²</span>
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            @click="save(scope.row, scope.$index)"
                            v-if="!scope.row.id"
                            >保存</el-button
                        >
                        <el-button
                            type="text"
                            @click="del(scope.row, scope.$index)"
                            v-if="scope.row.id"
                            >删除</el-button
                        >
                        <el-button
                            type="text"
                            @click="remove(scope.row, scope.$index)"
                            v-if="scope.row.isInput"
                            >移除</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-button type="primary" style="margin-top: 10px;" @click="add"
                >新增分区</el-button
            >
        </el-dialog>
    </div>
</template>

<script>
import { URL } from "../../../../config/url.supplier";

export default {
    name: "subArealand",
    props: {
        subDialogVisible: {
            type: Boolean,
            default: false
        },
        currentRow: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            visible: false,
            loading: false,
            tableData: []
        };
    },
    methods: {
        validateInputnext(value) {
            console.log(value.plantMinArea);
            value.plantMinArea = value.plantMinArea.replace(/[^0-9]/g, "");
            // if (value.plantMinArea.indexOf(0) == 0) {
            //     this.$set(value, "plantMinArea", "");
            // }
            // if (
            //     Number(value.rentMinArea) < Number(value.plantMinArea) ||
            //     Number(value.rentMinArea) % Number(value.plantMinArea) !== 0
            // ) {
            //     this.$message.error("单位租赁面积除以单位种植面积必须为整数");
            //     return;
            // }
        },
        validateInput(value) {
            console.log(value);
            value.rentMinArea = value.rentMinArea.replace(/[^0-9]/g, "");
            // if (value.rentMinArea.indexOf(0) == 0) {
            //     this.$set(value, "rentMinArea", "");
            // }
            // if (
            //     Number(value.rentMinArea) < Number(value.plantMinArea) ||
            //     Number(value.rentMinArea) % Number(value.plantMinArea) !== 0
            // ) {
            //     this.$message.error("单位租赁面积除以单位种植面积必须为整数");
            //     return;
            // }
        },

        add() {
            if (this.tableData.length === 0) {
                this.tableData.push({
                    districtNo: "",
                    plantMinArea: 3,
                    rentMinArea: 60,
                    isInput: true,
                    edit: true
                });
            } else {
                this.tableData.push({
                    districtNo: "",
                    plantMinArea: this.tableData[0].plantMinArea,
                    rentMinArea: this.tableData[0].rentMinArea,
                    isInput: true,
                    edit: false
                });
            }
        },

        close(e) {
            this.$emit("closeSub", e);
        },

        getlanddistrictList() {
            this.axios.get(URL.farm.farmLanddistrictList).then(res => {
                if (res.code === "0") {
                    this.tableData = res.data;
                    this.tableData.forEach(row => {
                        row.edit = false;
                        row.isInput = false;
                    });
                } else {
                    this.$message.error(res.msg);
                }
            });
        },

        del(row) {
            this.axios
                .delete(`${URL.farm.farmLanddistrictDelete}/${row.id}`)
                .then(res => {
                    if (res.code === "0") {
                        this.getlanddistrictList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },

        save(row) {
            if (
                this.tableData.length === 1 &&
                (row.districtNo == "" ||
                    row.plantMinArea == "" ||
                    row.rentMinArea == "")
            ) {
                this.$message.error("请输入");
                return;
            }
            if (row.districtNo == "") {
                this.$message.error("请输入");
                return;
            }
            if (
                Number(row.rentMinArea) < Number(row.plantMinArea) ||
                Number(row.rentMinArea) % Number(row.plantMinArea) !== 0
            ) {
                this.$message.error("单位租赁面积除以单位种植面积必须为整数");
                return;
            }
            this.axios
                .post(URL.farm.farmLanddistrictSave, {
                    districtNo: row.districtNo,
                    plantMinArea: row.plantMinArea,
                    rentMinArea: row.rentMinArea
                })
                .then(res => {
                    if (res.code === "0") {
                        row.isInput = false;
                        row.edit = false;
                        row.id = res.data;
                        this.getlanddistrictList();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },

        remove(row, index) {
            this.tableData.splice(index, 1);
        }
    },
    watch: {
        currentRow: {
            handler: function(oldV, newV) {
                console.log(newV);
            }
        },
        subDialogVisible: function(val) {
            this.visible = val;
            this.getlanddistrictList();
        }
    }

    // mounted() {
    //     this.getlanddistrictList();
    // }
};
</script>

<style></style>
