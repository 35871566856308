<template>
    <div>
        <el-dialog
            destroy-on-close
            :title="`${this.formData.id ? '编辑' : '新增'}租赁土地`"
            :visible.sync="addDialogVisible"
            width="900px"
            class="showAll_dialog"
            :before-close="close"
            center
        >
            <!-- {{  loading }} v-loading="loading" -->
            <el-form
                :inline="false"
                :model="formData"
                class="formData"
                ref="formData"
                :rules="rules"
                label-width="140px"
                v-loading="loading"
            >
                <el-form-item label="租赁土地标题：" prop="name">
                    <el-input
                        v-model="formData.name"
                        style="width:600px"
                        maxlength="32"
                        placeholder="请输入土地标题"
                        show-word-limit
                    />
                </el-form-item>
                <el-form-item label="土地编码：">
                    <el-input
                        v-model="formData.code"
                        style="width:600px"
                        maxlength="8"
                        disabled
                        placeholder="系统随机生成"
                        show-word-limit
                    />
                </el-form-item>
                <el-form-item label="土地说明：" prop="description">
                    <el-input
                        type="textarea"
                        :rows="2"
                        style="width:600px"
                        resize="none"
                        placeholder="请输入土地说明"
                        v-model="formData.description"
                        maxlength="64"
                        show-word-limit
                    ></el-input>
                </el-form-item>

                <el-form-item label="土地图片：">
                    <template slot="label">
                        <span style="color: red;">*</span>土地图片：
                    </template>
                    <span>图片</span>
                    <upload-images
                        :file-list.sync="formData.pictures"
                        width="100px"
                        :canvas="editGoods"
                        :limit="5"
                    ></upload-images>
                    <span>视频</span>
                    <UploadVodVideo
                        class="videoBox"
                        :filesSize="10240"
                        width="100px"
                        :file="formData.video ? formData.video.video : ''"
                        @update:file="changeVodVideoFiles"
                    />
                    <span v-if="formData.video && formData.video.video"
                        >视频封面图</span
                    >
                    <VideoLand
                        v-if="isVideoSnapshot"
                        :datum="formData.video"
                        @update:file="changeFilesImg"
                    />
                    <div
                        @click="isVideoSnapshot = true"
                        v-show="formData.video && formData.video.video"
                        style="width: 190px;height: 108px;line-height:108px;text-align:center;border: 1px dashed #e9e9e9;cursor: pointer;margin-bottom:20px"
                    >
                        <img
                            v-if="formData.cover"
                            :src="formData.cover"
                            style="width: 190px;height: 108px;border: 1px solid #e9e9e9;cursor: pointer;"
                            alt=""
                        />
                        <div class="" v-else>
                            <i
                                class="el-icon-plus avatar-uploader-icon"
                                style="font-size:28px;"
                            ></i>
                        </div>
                    </div>
                </el-form-item>

                <el-form-item label="土地详细地址：" prop="address">
                    <el-input
                        v-model="formData.address"
                        type="textarea"
                        :rows="2"
                        style="width:600px"
                        maxlength="64"
                        placeholder="请输入土地所在的详情地址"
                        show-word-limit
                    />
                </el-form-item>
                <el-form-item label="租赁物料费用：" prop="feeOfFirstRent">
                    <el-input
                        type="number"
                        v-model="formData.feeOfFirstRent"
                        style="width:600px"
                        placeholder="请输入首次租赁开荒费用 "
                    />&ensp;元
                </el-form-item>
                <el-form-item label="土地分区：" prop="landDistrictId">
                    <el-select
                        @change="getItem"
                        v-model="formData.landDistrictId"
                        placeholder="请选择土地区号"
                        :disabled="formData.id?true:false"
                        style="width:600px"
                    >
                        <el-option
                            v-for="(item, index) in landdistrictList"
                            :key="index"
                            :disabled="item.checkType == 1"
                            :label="item.districtNo"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item prop="landArea">
                    <template slot="label">
                        土地总面积
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="土地总面积是指你当前这个区域可以租赁给用户的土地总面积，当土地总面积/单位租赁面积，得到有余数时，余数的土地则不会被租赁出去"
                            placement="top"
                        >
                            <i class="el-icon-question" />
                        </el-tooltip>
                        ：
                    </template>

                    <el-input
                        @input="getAreaCode"
                        v-model="formData.landArea"
                        style="width:600px"
                        :disabled="formData.id?true:false"
                        maxlength="8"
                        placeholder="请输入土地总面积"
                    />&ensp;m²
                </el-form-item>
                <el-form-item prop="blockNo">
                    <template slot="label">
                        分区号段
                        <el-tooltip
                            class="item"
                            effect="dark"
                            content="单位租赁面积是指可以单次租赁给用户的土地面积是多大"
                            placement="top"
                        >
                            <i class="el-icon-question" />
                        </el-tooltip>
                        ：
                    </template>
                    <div style="width:100%;">
                        <el-input
                            v-model="formData.blockNoStart"
                            disabled
                            style="width:46%;"
                            maxlength="8"
                            placeholder=""
                        />
                        --
                        <el-input
                            v-model="formData.blockNoEnd"
                            disabled
                            style="width:46%;"
                            maxlength="8"
                            placeholder=""
                        />
                    </div>
                </el-form-item>
                <el-form-item label="关联租赁套餐：" prop="packageId">
                    <div>
                        <el-checkbox-group
                            @change="changecheck"
                            v-model="formData.packageId"
                            v-if="packageList.length > 0"
                        >
                            <div
                                style="margin-bottom: 10px;"
                                v-for="(item, i) in packageList"
                                :key="i"
                            >
                                <el-checkbox :label="item.id"
                                    >{{ item.name }}&emsp;&emsp;{{
                                        item.rentCycle == 1 ? "包年" : ""
                                    }}&emsp;&emsp;¥&emsp;&emsp;{{
                                        item.price
                                    }}</el-checkbox
                                >
                            </div>
                        </el-checkbox-group>
                    </div>
                </el-form-item>
                <el-form-item label="农场管理员：" prop="farmAdminId">
                    <el-select
                        v-model="formData.farmAdminId"
                        placeholder="请选择农场管理员"
                        style="width:600px"
                    >
                        <el-option
                            v-for="(item, index) in userListAdimin"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="任务大厅服务者：" prop="serviceUserId">
                    <el-select
                        v-model="formData.serviceUserId"
                        placeholder="请选择农场服务者"
                        style="width:400px"
                        multiple
                    >
                        <el-option
                            v-for="(item, index) in userListService"
                            :key="index"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                    支持多选,多个以英文逗号隔开
                </el-form-item>
                <el-form-item label="土地详情：" prop="remark">
                    <div class="quillBox">
                        <div class="quillBox">
                            <quill-editor
                                class="ql-editor"
                                style="height: 400px;"
                                v-model="formData.remark"
                                :options="editorOption"
                                ref="myQuillEditor"
                            />
                            <newUploadImages
                                style="display: none;"
                                ref="iviewUp"
                                id="iviewUp"
                                width="80px"
                                height="80px"
                                @update:file="
                                    cover => uploadSuccess(cover, 'richText')
                                "
                            />
                            <UploadVideo
                                v-if="iviewUpVideo"
                                ref="iviewUpVideo"
                                id="iviewUpVideo"
                                quillVideo
                                @update:file="
                                    video => uploadSuccessVideo(video, 'video')
                                "
                            />
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import UploadImagesOne from "@/components/public/upload/uploadImagesOne.vue";
import VideoLand from "@/components/supplier/editPostage/video-land.vue";
import uploadImages from "@/components/public/upload/uploadImages";
import newUploadImages from "@public/upload/newUploadImages";
import uploadImgWithNoCanvas from "@public/upload/uploadImgWithNoCanvas.vue";
import UploadVodVideo from "@/components/public/upload/uploadVodVideo.vue";
import UploadVideo from "@public/upload/uploadVideo";
import * as formRules from "@/assets/js/formRules";
import { editGoods } from "@/config/imageSize";
import { miniToolbarOptionsVideo } from "@/assets/js/utils";

import { URL } from "../../../../config/url.supplier";

export default {
    name: "addRendLand",
    props: {
        addDialogVisible: {
            type: Boolean,
            default: false
        },
        currentRow: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        uploadImgWithNoCanvas,
        UploadImagesOne,
        uploadImages,
        newUploadImages,
        UploadVodVideo,
        VideoLand,
        UploadVideo
    },
    data() {
        const checkPrice = (rule, value, callback) => {
            const index = rule.field.match(/[0-9]+/g);
            const row = this.goodsSkuList[index];
            const errorText = this.judgeSecondsKillEditPrice(row);
            if (errorText) return callback(new Error(errorText));
            callback();
        };

        const isUnit = (rule, value, callback) => {
            if (value === "") {
                if (this.ruleForm.riskRemoveType === 1) {
                    callback(new Error("请选择帮扶单位"));
                } else if (this.ruleForm.riskRemoveType === 2) {
                    callback(new Error("请选择帮扶人"));
                }
            } else {
                callback();
            }
        };
        return {
            rentMinArea: 0,
            visible: false,
            loading: false,
            formData: {
                videos: {},

                name: "",
                code: "",
                description: "",

                pictures: [],

                address: "",
                feeOfFirstRent: "",
                landDistrictId: "",
                landArea: "",

                blockNoStart: "",
                blockNoEnd: "",

                packageId: [],
                farmAdminId: "",
                serviceUserId: [],
                remark: ""
            },
            formRules,
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入土地标题",
                        trigger: "blur"
                    }
                    // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                ],
                code: [
                    {
                        required: true,
                        message: "请输入土地编码,且编码唯一,不可修改",
                        trigger: "blur"
                    }
                ],
                description: [
                    {
                        required: true,
                        message: "请上传土地图片",
                        trigger: "blur"
                    }
                ],
                // 图片 视频校验 validator: checkPrice,
                pictures: [
                    {
                        required: true,
                        message: "请输入土地说明",
                        trigger: "blur"
                    }
                ],
                address: [
                    {
                        required: true,
                        message: "请输入土地所在的详细地址",
                        trigger: "change"
                    }
                ],
                feeOfFirstRent: [
                    {
                        required: true,
                        message: "请输入首次租赁开荒费用",
                        trigger: "change"
                    }
                ],
                landDistrictId: [
                    {
                        required: true,
                        message: "请选择土地区号",
                        trigger: "change"
                    }
                ],
                landArea: [
                    {
                        required: true,
                        message: "请输入土地总面积",
                        trigger: "change"
                    }
                ],
                // 分区号段
                blockNo: [
                    {
                        validator: isUnit,
                        required: true,
                        min: 4,
                        message: "请输入土地总面积",
                        trigger: "change"
                    }
                ],
                packageId: [
                    { required: true, message: "请选择", trigger: "change" }
                ],
                farmAdminId: [
                    {
                        required: true,
                        message: "请选择农场管理员",
                        trigger: "change"
                    }
                ],
                serviceUserId: [
                    {
                        type: "array",
                        required: true,
                        message: "请选择农场服务者",
                        trigger: "change"
                    }
                ],
                remark: [
                    { required: true, message: "请输入", trigger: "change" }
                ]
            },
            options: [],
            radio: 3,
            value: "",
            value1: [],
            editGoods,
            isVideoSnapshot: false,

            iviewUpVideo: true,
            editorOption: {
                placeholder: "请在这里输入",
                theme: "snow",
                modules: {
                    toolbar: {
                        container: miniToolbarOptionsVideo, // 工具栏
                        handlers: {
                            image: function(value) {
                                if (value) {
                                    document
                                        .querySelector("#iviewUp input")
                                        .click();
                                } else {
                                    this.quill.format("image", false);
                                }
                            },
                            video: function(value) {
                                if (value) {
                                    document
                                        .querySelector("#iviewUpVideo input")
                                        .click();
                                } else {
                                    this.quill.format("video", false);
                                }
                            }
                        }
                    }
                },
                clipboard: {
                    matchers: [[Node.ELEMENT_NODE, this.handleCustomMatcher]]
                }
            },
            landdistrictList: [],
            packageList: [],
            userListAdimin: [],
            userListService: []
        };
    },
    methods: {
        changecheck(val) {
            console.log(val);
        },
        formatNumber(num) {
            let str = String(num);
            if (str.length < 4) {
                while (str.length < 4) {
                    str = "0" + str;
                }
            }
            return str;
        },
        getAreaCode(val) {
            this.formData.landArea = this.formData.landArea
                .replace(/[^\-\d.]/g, '') // 只能输入   数字 小数点 -
                .replace(/\-{2,}/g, "-") // 只能出现一次-
                .replace(/^0+(\d)/, '$1') // 如果第一位是 0 就替换成后面的数字
                .replace(/^\./, '0.') // 如果第一位是 . 就 替换成  0.
                .match(/^[\d\-]*(\.?\d{0,2})/g)[0] || '' // 开头只能允许数字或者 -
            if (!this.rentMinArea) {
                return;
            }
            if (this.formData.landArea) {
                let start;
                if (this.formData.landArea / this.rentMinArea < 1) {
                    start = 0;
                } else {
                    start = 1;
                }

                let end = Math.floor(this.formData.landArea / this.rentMinArea);
                this.formData.blockNoStart = this.formatNumber(start);
                this.formData.blockNoEnd = this.formatNumber(end);
                // let start = 1;
                // let end =
                //     this.formData.landArea / this.rentMinArea < 1
                //         ? Math.ceil(this.formData.landArea / this.rentMinArea)
                //         : Math.floor(this.formData.landArea / this.rentMinArea);
                // this.formData.blockNoStart = this.formatNumber(start);
                // this.formData.blockNoEnd = this.formatNumber(end);
            }
        },
        getItem(val) {
            const item = this.landdistrictList.find(res => {
                return (res.landDistrictId = val);
            });
            this.rentMinArea = item.rentMinArea;
            if (!this.formData.landArea) {
                return;
            }
            if (this.rentMinArea) {
                let start;
                if (this.formData.landArea / this.rentMinArea < 1) {
                    start = 0;
                } else {
                    start = 1;
                }

                let end = Math.floor(this.formData.landArea / this.rentMinArea);
                this.formData.blockNoStart = this.formatNumber(start);
                this.formData.blockNoEnd = this.formatNumber(end);
                // let start = 1;
                // let end =
                //     (this.formData.landArea / this.rentMinArea) < 1
                //         ? Math.ceil(this.formData.landArea / this.rentMinArea)
                //         : Math.floor(this.formData.landArea / this.rentMinArea);
                // this.formData.blockNoStart = this.formatNumber(start);
                // this.formData.blockNoEnd = this.formatNumber(end);
            }
        },
        changeVodVideoFiles(videoFile) {
            // this.formData.video = videoFile;
            // this.$nextTick(() => {
            //     this.formData.video = videoFile;
            // });
            this.$set(this.formData,"video", videoFile)
            if (JSON.stringify(videoFile) === "{}") {
                this.formData.cover = null
            }
            this.$forceUpdate()
        },

        uploadSuccess(url, key) {
            if (key === "banner") {
                this.row.banner = url;
            } else if (key === "mainIcon") {
                this.cultureForm.mainIcon = url;
            } else if (key === "richText" && url) {
                let quill = this.$refs.myQuillEditor.quill;
                let length = quill.getSelection().index; // 获取光标所在位置
                quill.insertEmbed(length, "image", url);
                quill.setSelection(length + 1); // 调整光标到最后
            }
        },

        uploadSuccessVideo({ video }) {
            let quill = this.$refs.myQuillEditor.quill;
            let length = quill.getSelection().index;
            quill.insertEmbed(length, "video", video);
            quill.setSelection(length + 1);
            this.iviewUpVideo = false;
            this.$nextTick(() => {
                this.iviewUpVideo = true;
            });
        },

        close(e) {
            this.$emit("closeAdd", e);
        },

        getFarmLanddistrictList() {
            this.axios.get(URL.farm.farmLanddistrictLabelList).then(res => {
                if (res.code === "0") {
                    this.landdistrictList = res.data;
                    // this.formData.blockNoStart = "0001";
                    // this.formData.blockNoEnd =
                    //     res.data.length.toString().length == 1
                    //         ? "000" + res.data.length
                    //         : "00" + res.data.length;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },

        getfarmRentPackagePage() {
            this.axios
                .post(URL.farm.farmRentPackagePage, {
                    pageNo: 1,
                    pageSize: 999,
                    enable: 1
                })
                .then(res => {
                    if (res.code === "0") {
                        this.packageList = res.data.records;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },

        getfarmUserPage() {
            this.axios
                .post(URL.farm.farmUserPage, { pageNo: 1, pageSize: 999 })
                .then(res => {
                    if (res.code === "0") {
                        this.userListAdimin = [];
                        this.userListService = [];
                        res.data.records.forEach(item => {
                            if (item.type == 1) {
                                this.userListAdimin.push(item);
                            } else {
                                this.userListService.push(item);
                            }
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },

        changeFilesImg(img) {
            if (img.indexOf("http") != "-1") {
                this.formData.cover = img;
            }
            this.isVideoSnapshot = false;
        },

        getfarmRentPackagePagenew() {
            this.axios
                .post(URL.farm.farmRentPackagePage, {
                    pageNo: 1,
                    pageSize: 999,
                    enable: 1
                })
                .then(res => {
                    if (res.code === "0") {
                        this.formData = {
                            name: "",
                            code: "",
                            description: "",

                            address: "",
                            feeOfFirstRent: "",
                            landDistrictId: "",
                            landArea: "",

                            blockNoStart: "",
                            blockNoEnd: "",

                            farmAdminId: "",
                            serviceUserId: [],
                            remark: "",
                            id: ""
                        };
                        this.$refs.formData.resetFields();
                        this.formData.pictures = [];
                        this.formData.cover = "";
                        this.formData.video = {};
                        this.$set(this.formData, "packageId", []);
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getAreaCodeNew(val) {
            this.axios.get(URL.farm.farmLanddistrictLabelList).then(res => {
                if (res.code === "0") {
                    this.landdistrictList = res.data;
                    const item = this.landdistrictList.find(res => {
                        return res.id == val.landDistrictId;
                    });
                    this.rentMinArea = item.rentMinArea;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },

        submit() {
            if (this.formData.video.video) {
                if(!this.formData.cover) return this.$message.error("请上传视频封面图");
            }
            if (this.formData.pictures.length == 0) {
                return this.$message.error("请至少上传一张图片、视频可以选填");
            }
            this.$refs.formData.validate(valid => {
                if (!valid) {
                    return;
                }
                let params = { ...this.formData };
                params.pictures = {
                    pics: [...this.formData.pictures]
                };
                params.video = {
                    cover: this.formData.cover,
                    video: this.formData.video.video
                };
                let url = this.formData.id
                    ? URL.farm.farmLandUpdate
                    : URL.farm.farmLandSave;
                this.axios[this.formData.id ? "put" : "post"](url, params).then(
                    res => {
                        if (res.code === "0") {
                            this.close("get");
                            this.$message({
                                message: "操作成功",
                                type: "success"
                            });
                        } else {
                            this.$message.error(res.msg);
                        }
                    }
                );
            });
        }
    },

    watch: {
        currentRow(n) {
            if (n.id) {
                const {
                    id,
                    name,
                    code,
                    description,
                    pictures,
                    address,
                    feeOfFirstRent,
                    landDistrictId,
                    landArea,
                    blockNoStart,
                    blockNoEnd,
                    serviceUserId,
                    farmAdminId,
                    remark,
                    packages,
                    video
                } = n;
                this.formData = {
                    name,
                    code,
                    description,

                    address,
                    feeOfFirstRent,
                    landDistrictId,
                    landArea,

                    blockNoStart,
                    blockNoEnd,

                    farmAdminId,
                    serviceUserId,
                    remark,
                    id
                };
                this.getAreaCodeNew(n);
                this.formData.blockNoStart = this.formatNumber(blockNoStart);
                this.formData.blockNoEnd = this.formatNumber(blockNoEnd);

                this.formData.pictures = pictures.pics;
                this.formData.packageId = this.$set(
                    this.formData,
                    "packageId",
                    packages.map(v => v.id)
                );
                this.formData.cover = video && video.cover ? video.cover : "";
                this.formData.video = video
                    ? {
                          video: video.video
                      }
                    : {};
            } else {
                this.getfarmRentPackagePagenew();
            }
        },
        addDialogVisible(n) {
            if (n) {
                this.getfarmRentPackagePage();
                this.getFarmLanddistrictList();
                this.getfarmUserPage();
            }

            this.visible = n;
        }
    }
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__body {
    height: 550px;
    overflow-y: scroll;
}

.formData {
    padding: 0 20px;
    h4 {
        text-align: center;
    }
    .quillBox {
        width: 600px;
        height: 400px;
        /deep/.ql-formats {
            line-height: 0;
        }
        /deep/.ql-toolbar {
            line-height: 0;
        }
        /deep/.viewOnly {
            height: 0px;
            overflow: hidden;
            border-top: 0;
            padding: 0px;
        }
    }
    .upload-item-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .marginL10 {
        margin-left: 10px;
    }
    h4 {
        margin: 15px 0;
    }
}
.avatar-uploader-quill {
    display: none;
}

.videoBox {
    position: relative;
    /deep/ .el-upload--picture-card {
        width: 100px;
        height: 100px;
    }
    /deep/ .avatar-uploader-icon {
        position: absolute;
        top: 50%;
        left: 11%;
        margin-left: -35px;
        margin-top: -14px;
    }
}

.videoTips {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000;
    z-index: 2009 !important;
}

/deep/ .el-form-item__content {
    min-width: 140px !important;
}

.quillBox {
    /deep/ .ql-container .ql-video {
        height: 220px;
    }
}
</style>
