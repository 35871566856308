<template>
  <div class="">
    <div class="upload-files-list clearfix">
      <draggable
          v-model="list"
          :move="onMove"
          @start="isDragging = true"
          @end="isDragging = false"
          v-if="!0"
      >
        <div
            class="upload-item upload-item-success"
            :style="sizeStyle"
            v-for="(file, index) in list"
            :key="index + '_' + file"
        >
          <img class="upload-item-image" :src="file" />
          <span class="upload-item-actions">
            <span
                class="upload-item-actions-button"
                @click="handlePictureCardPreview(file)"
            >
              <i class="el-icon-zoom-in"></i>
            </span>
            <span
                v-if="!readOnly"
                class="upload-item-actions-button"
                @click="handleRemove(file, index)"
            >
              <i class="el-icon-delete color-danger"></i>
            </span>
          </span>
        </div>
      </draggable>
      <el-upload
          class="upload-item border-none"
          ref="uploadImages"
          :style="sizeStyle"
          :action="actionImage"
          :headers="headers"
          :limit="limit"
          :data="params"
          multiple
          :disabled="disabled"
          list-type="picture-card"
          :show-file-list="false"
          :http-request="handleUpLoadImage"

          :on-error="function() { uploadErrorMulti.apply(this, [...arguments]);}"
          v-loading="loading"
          v-show="list.length < limit"
      >
        <!-- :on-exceed="onExceed" -->
        <i slot="default" class="el-icon-plus"></i>
      </el-upload>
    </div>
    <el-alert
        class="upload-hint-text margin-top-10"
        type="info"
        v-if="showHintText && !readOnly"
    >
      <span class="" slot="title">
        最多上传{{ limit }}张图片，单张图片需限制在{{ filesSize
        }}{{ filesSizeUnit }}以内。
      </span>
    </el-alert>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
    <el-dialog
        width="600px"
        v-if="isCropper"
        :visible.sync="dialogVisibleCropper"
        @close="fileImage = ''"
        append-to-body
    >
      <AdvancedCropper
          @changeCropper="changeCropper"
          :aspect-ratio="aspectRatio"
          :canvas="canvas"
          :image="fileImage"
      />
    </el-dialog>
  </div>
</template>

<script>
import AdvancedCropper from "@/components/public/advancedCropper";
import { uploadImageMixins } from "./uploadImage";
import draggable from "vuedraggable";
import { matchingTwoObject } from "@/assets/js/utils";

export default {
  name: "uploadImages",
  mixins: [uploadImageMixins],
  components: {
    draggable,
    AdvancedCropper
  },
  props: {
    fileList: {
      // 默认回显列表
      type: Array
      // default:function(){
      //   return []
      // }
    },
    limit: {
      type: Number,
      default: 0
    },
    number: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      /* 文件上传 */
      list: [],
      fileImage: "",
      /* 上传排序问题*/
      listBeforeSort: [],
      listBeforeSelectNumber: 0, // 记录本次选择几张
      listBeforeNumber: -1, // 记录上传前有几张
      uploadNum: 0,
      disabled: false
    };
  },
  created() {
    this.setFileList(this.fileList);
  },
  methods: {
    /** 判断更新、冒泡 **/
    triggerFileList() {
      if (!this.judgeList()) {
        this.$emit("update:fileList", this.list);
      }
    },
    judgeList() {
      return matchingTwoObject(this.list, this.fileList, "上传图片");
    },
    /** 初始化设值 **/
    setFileList(list) {
      if (Array.isArray(list)) {
        try {
          list = JSON.parse(JSON.stringify(list));
        } catch (e) {
          console.error("uploadImage setFileList JSON error");
        }
        if (list.length > this.limit) {
          list = list.filter((res, index) => {
            return index < this.limit;
          });
        }
      } else {
        list = [];
      }
      this.list = list;
    },
    /** 删除、移动图片 **/
    // 删除图片
    handleRemove(file, index) {
      this.$confirm("确认删除图片？", "提示", {
        // cancelButtonText: '取消',
      }).then(action => {
        this.list = this.list.filter((res, i) => {
          return index !== i;
        });
        const { uploadImages } = this.$refs;
        if (uploadImages) {
          uploadImages.uploadFiles.splice(index, 1);
        }
      });
    },
    // 图片移动
    onMove({ relatedContext, draggedContext }) {},
    /**
     * 文件上传之前
     */
    async uploadBeforeMulti(file, origin) {
      if (!this.uploadBefore(...arguments)) return false;
      this.plusSelectRecord(file);
      return true;
    },
    /**
     * 文件上传错误
     */
    uploadErrorMulti(file) {
      this.markSelectRecord(file, 2);
      this.uploadError(file);
    },
    /**
     * 按选择顺序排序*/
    //增加待标记记录
    plusSelectRecord(file = {}) {
      if (this.listBeforeNumber < 0) {
        this.listBeforeNumber = this.list.length - 1;
      }
      this.listBeforeSort.push({
        name: file.name,
        uid: file.uid,
        type: 0 // 0-待上传,1-上传成功,2-上传失败
      });
    },
    // 标记成功或失败状态
    markSelectRecord(file = {}, type = 1, url = "") {
      let list = this.listBeforeSort;
      let uid = file.uid;
      let index = list.map(res => res.uid).indexOf(uid);
      if (index < 0) return false;
      list[index].type = type;
      if (type === 1) {
        list[index].url = url;
      }
      this.removeSelectRecord();
    },
    // 全部标记状态后清空
    removeSelectRecord() {
      let list = this.listBeforeSort;
      if (list.every(res => res.type)) {
        // this.$refs.uploadImages.clearFiles()
        this.closeLoading();
        let imgUrlArray = this.listBeforeSort
            .map(res => res.url)
            .filter(res => res);
        this.list.push(...imgUrlArray);
        this.listBeforeNumber = -1;
        this.listBeforeSelectNumber = 0;
        this.listBeforeSort = [];
      }
    },
    /**
     * 文件上传回调
     */
    uploadCallBack(data) {
      const { type, file, url } = data;
      if (type == "error") {
        this.uploadErrorMulti(file);
      } else {
        if (this.limit > 1) {
          this.list.push(url);
        } else {
          this.list = [url];
        }
      }
    }
  },
  watch: {
    fileList(newVal) {
      if (!this.judgeList()) {
        this.setFileList(newVal);
      }
    },
    list(newVal) {
      this.triggerFileList();
    }
  }
};
</script>
<style lang="less">
.upload-item {
  .el-upload {
    width: 100%;
    height: 100%;
    line-height: 1;
    position: relative;
    box-sizing: border-box;

    .el-icon-plus {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>

<style scoped lang="less">
@import "../../../assets/css/data";

.upload-files-list {
  line-height: 1;

  .upload-item {
    float: left;
  }
}

.upload-item {
}

.upload-item {
  border-radius: 6px;
  overflow: hidden;
  border: @border-width-solid solid @border-color-one;
  box-sizing: border-box;
  position: relative;

  &:not(.avatar-uploader) {
    margin-bottom: 10px;
    margin-right: 10px;
    display: inline-block;
  }

  &.border-none {
    border: none;
  }
}

.upload-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-item-actions {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  opacity: 0;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

  &:hover {
    opacity: 1;

    .upload-item-actions-button {
      display: inline-block;
    }
  }
}

.upload-item-actions-button {
  display: none;
  transition: 0.4s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }

  &.disabled {
    color: @color-info;

    &:hover {
      transform: none;
    }
  }
}

.upload-item-actions-button-delete {
  @width: 26px;
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 2;
  border-radius: 50%;
  background-color: #f2f2f2;
  color: #ff0000;
  text-align: center;

  &:hover {
    transform: none;
  }
}

.upload-hint-text {
  line-height: initial;
}
</style>
